import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatToolbarModule,
  MatCheckboxModule,
} from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ProgressBarModule } from "angular-progress-bar";
import { ChartsModule } from "ng2-charts";
import { ToastrModule } from "ngx-toastr";

import { LoginComponent } from "./account/login/login.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SidenavComponent } from "./layout/sidenav/sidenav.component";
import { SurveyComponent } from "./survey/survey/survey.component";
import { SuccessComponent } from "./utils/success/success.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpconfigInterceptor } from './core/interceptor/httpconfig.interceptor'
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER } from 'ngx-ui-loader';
import { DatePipe } from '@angular/common';
import { UpdatepasswordComponent } from './password/updatepassword/updatepassword.component';
import { IntroductionComponent } from './survey/introduction/introduction.component';
import { PptGenerationComponent } from './utils/ppt-generation/ppt-generation.component';
import { TabsModule } from 'ngx-bootstrap/tabs';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsType: SPINNER.ballScaleMultiple,
  hasProgressBar: false
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidenavComponent,
    SurveyComponent,
    SuccessComponent,
    UpdatepasswordComponent,
    IntroductionComponent,
    // PptGenerationComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    ProgressBarModule,
    MatProgressBarModule,
    MatRadioModule,
    MatMenuModule,
    ChartsModule,
    MatCheckboxModule,
    TabsModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ToastrModule.forRoot({
      preventDuplicates: true,
      closeButton: true,
      timeOut: 5000,
    })
    // NgxLoadingModule.forRoot({})
  ],
  entryComponents: [SuccessComponent, UpdatepasswordComponent, IntroductionComponent],
  providers: [DatePipe, { provide: HTTP_INTERCEPTORS, useClass: HttpconfigInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule { }
