import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpconfigInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            const newReq = request.clone({
                headers: request.headers.set("Authorization", "Bearer " + token)
            })
        }
        return next.handle(request).pipe(catchError(err => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
                console.log(err);

            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}
