import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { UsersData } from "../../user";
import { ApiService } from 'src/app/core/services/api.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private apiservice: ApiService,
    private ngxService: NgxUiLoaderService

  ) { }

  ngOnInit() {
      this.createForm();
  }

  /**
   * Create form
   */
  createForm() {
    this.formGroup = this.formBuilder.group({
      useremail: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
          ),
        ],
      ],
      password: ["", Validators.required],
    });
  }

  /**
   * set error message
   */
  getError(el) {
    switch (el) {
      case "user":
        if (this.formGroup.get("useremail").hasError("required")) {
          return "Email required";
        } else if (this.formGroup.get("useremail").hasError("pattern")) {
          return "Enter a valid email address";
        }
        break;
      case "pass":
        if (this.formGroup.get("password").hasError("required")) {
          return "Password required";
        }
        break;
      default:
        return "";
    }
  }

  /**
   * On submit form
   */
  onSubmit() {
    // stop here if form is invalid
    if (this.formGroup.invalid) {
      return;
    }
    console.log(this.formGroup.controls.useremail.value);
    const data = {
      email: this.formGroup.controls.useremail.value,
      password: this.formGroup.controls.password.value,
    };
    this.ngxService.start();
    this.apiservice.login(data)
      .subscribe(result => {
        const valid = UsersData.filter((el) => el.role === result.roles[0].role);
        sessionStorage.setItem("currentUser", JSON.stringify(result.email));
        sessionStorage.setItem("role", JSON.stringify(result.roles[0].role));
        sessionStorage.setItem("id", JSON.stringify(result._id));
        sessionStorage.setItem("token", JSON.stringify(result.token));
        sessionStorage.setItem("sidenav", JSON.stringify(valid[0].sidenav));
        this.toaster.success("Login Successfull");
        this.router.navigate([valid[0].sidenav[0].path]);
        this.ngxService.stop();
      }, err => {
        this.ngxService.stop();
        this.toaster.error(err);
      })

  }
}
