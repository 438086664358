import { MediaMatcher } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { UpdatepasswordComponent } from 'src/app/password/updatepassword/updatepassword.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit, OnDestroy {
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    public dialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 1024px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  mobileQuery: MediaQueryList;
  fillerNav = [];
  accountuser: any;

  private _mobileQueryListener: () => void;
  ngOnInit() {
    this.accountuser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.fillerNav = JSON.parse(sessionStorage.getItem("sidenav"));
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(["/login"]);
  }


  updatePassword() {
    const dialogRef = this.dialog.open(UpdatepasswordComponent, {
      width: "500px",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      console.log("The dialog was closed", confirm);
      if (confirm) {
        this.toastr.success("Password Updated Successfully")
      }
    })
  }

}
