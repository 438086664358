import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { SuccessComponent } from "../../utils/success/success.component";
import { ApiService } from 'src/app/core/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmailValidator } from 'src/app/core/validator/email.validator';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IntroductionComponent } from '../introduction/introduction.component';

@Component({
  selector: "app-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.scss"],
})
export class SurveyComponent implements OnInit {
  clientForm: FormGroup;
  applicationBriefForm: FormGroup;
  questionFormlist1: FormGroup;
  questionFormlist2: FormGroup;
  thankyou: boolean = false;
  submitList1: boolean = false;
  page: number = 2;
  progressBarValue: number = 0;
  question = [];
  campaignId: any;
  constructor(private formBuilder: FormBuilder, private ngxService: NgxUiLoaderService,
    public dialog: MatDialog, private apiService: ApiService, private route: Router,
    public toastr: ToastrService, private router: ActivatedRoute) { }
  questionset1 = [];
  questionset2 = [];
  privacy = {
    email: false,
    mail: false,
    phone: false
  }
  ngOnInit() {
    this.router.queryParams.subscribe(res => {
      console.log(res);
      this.campaignId = res.cId;
    })
    this.getIntroduction();
    this.getAllQusetions();
    this.createForm();
  }


  /**
   *Itroduction popup
   */
  getIntroduction() {
    const dialogRef = this.dialog.open(IntroductionComponent, {
      width: "800px",
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh'
    });
    dialogRef.afterClosed().subscribe((confirm) => {
      console.log("The dialog was closed", confirm);
    })
  }


  /**
   * Create Form fo Client Details
   */
  createForm() {
    this.clientForm = this.formBuilder.group({
      name: ["", Validators.required],
      email: [
        "",
        [Validators.required,
        EmailValidator.cannotContainDomain,
        Validators.pattern(
          "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
        ),
        ],
      ],
      number: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
      companyName: ["", Validators.required],
      industry: ["", Validators.required]
    });
  }


  // Listen for input event on numInput.
  onkeydown(e) {
    if (!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode == 8)) {
      return false;
    }
  }


  /**
   * CreateForm from Application Breify,question list one and two
   */
  createForm2() {
    this.applicationBriefForm = this.formBuilder.group({
      workloadName: ["", Validators.required],
      workloadBrief: ["", Validators.required],
      workloadEnvironment: ["", Validators.required],
      packageDetails: ["", Validators.required],
      middlewareversion: ["", Validators.required],
      programminglanguage: ["", Validators.required],
      databaseRequirement: ["", Validators.required],
      osVersions: ["", Validators.required],
      virtualizationDetail: ["", Validators.required],
      scalabilityRequirement: ["", Validators.required],
      businessSLA: ["", Validators.required],
      securityRequirement: ["", Validators.required],
    });


    /**
   *Question List for mutilply choice
   */
    this.questionFormlist1 = this.formBuilder.group({
      question: this.formBuilder.array(
        this.questionset1.map(el => {
          const group = this.grouplist1();
          group.patchValue({
            question: el.question,
            questionID: el._id,
            options: el.optionData
          })
          return group

        })
      )
    })


    /**
   * Question List for yes or no type
   */
    this.questionFormlist2 = this.formBuilder.group({
      question: this.formBuilder.array(
        this.questionset2.map(el => {
          const group = this.grouplist2();
          group.patchValue({
            question: el.question,
            questionID: el._id,
            options: el.optionData
          })
          return group

        })
      )
    })
  }


  /**
 * Get all question--Api call
 */
  getAllQusetions() {
    this.apiService.getAllQuestions()
      .subscribe(res => {
        this.question = res;
        res.map(el => {
          if (el.optionData.length > 2) {
            this.questionset1.push(el)
          }
          else {
            this.questionset2.push(el)
          }
        })
        this.createForm2();
      }, err => {
        this.toastr.error("Server Error")
      })
  }

  /**
   * storing selected option of the questions answered based on form and updating progressbar
   */
  temp = [];
  selectedRadio(id, index, questionlist, item) {
    if (questionlist === 'questionFormlist1') {
      const controlArray = this.questionFormlist1.get('question') as FormArray;
      controlArray.controls[index].get('selected').setValue(id);
      this.temp.findIndex(x => x === item.value.questionID) == -1 ? this.temp.push(item.value.questionID) : console.log("object already exists")
    } else {
      const controlArray = this.questionFormlist2.get('question') as FormArray;
      controlArray.controls[index].get('selected').setValue(id);
      this.temp.findIndex(x => x === item.value.questionID) == -1 ? this.temp.push(item.value.questionID) : console.log("object already exists")
    }
    this.progressBarValue = +((100 * this.temp.length) / this.question.length).toFixed();
  }


  /**
   * Create Form for listing questions having four options
   */
  grouplist1(): FormGroup {
    const form = this.formBuilder.group({
      question: [''],
      questionID: [''],
      options: [],
      description: [''],
      selected: ['', Validators.required]

    });
    return form;
  }

  /**
 * Create Form for listing questions having two options
 */
  grouplist2(): FormGroup {
    const form = this.formBuilder.group({
      question: [''],
      questionID: [''],
      options: [],
      selected: ['', Validators.required],
      description: ['', Validators.required]

    });
    return form;
  }


  /**
   * setting error messages
   */
  getError(el) {
    switch (el) {
      case "email":
        if (this.clientForm.get("email").hasError("required")) {
          return "Email required";
        } else if (this.clientForm.get("email").hasError("pattern")) {
          return "Enter a valid email address";
        } else if (this.clientForm.get("email").hasError("cannotContainDomain")) {
          return "Enter a valid email address";
        }
        break;
      case "name":
        if (this.clientForm.get("name").hasError("required")) {
          return "Name required";
        }
        break;
      case "number":
        if (this.clientForm.get("number").hasError("required")) {
          return "Number required";
        } else if (this.clientForm.get("number").hasError("pattern")) {
          return "Please enter valid Number";
        }
        break;
      case "companyName":
        if (this.clientForm.get("companyName").hasError("required")) {
          return "Company Name required";
        }
        break;
      case "industry":
        if (this.clientForm.get("industry").hasError("required")) {
          return "Industry required";
        }
        break;
      default:
        return "Required";
    }
  }


  /**
   * page Increament
   */
  pageIncreament() {
    this.page++;
    this.gotoTop();
  }


  /**
   * On next click check form validty
   */
  nextClicked() {
    if (this.page === 1) {
      this.clientForm.invalid === false ? this.pageIncreament() : this.clientForm.markAllAsTouched(); return;
      // this.pageIncreament()
    }else if(this.page === 2){
        this.pageIncreament()
        return;
    } else if (this.page === 3) {
      this.applicationBriefForm.invalid === false ? this.pageIncreament() : this.applicationBriefForm.markAllAsTouched(); return;
    } else if (this.page === 4) {
      this.questionFormlist1.invalid === false ? this.submitList1 = false : this.submitList1 = true;
      this.questionFormlist1.invalid === false ? this.pageIncreament() : this.toastr.error("Please Answer all the Questions"); return;
    } else {
      this.pageIncreament();
    }
  }


  /**
 * Move to previous page
 */
  previousClicked() {
    if (this.page > 2) {
      this.page--;
      this.gotoTop();
    }
  }

  /**
* scroll page to top
*/
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }


  Modes = ["Step by Step", "Bulk Upload"]
  assessmentMode = "Bulk Upload"
  dynamic = {
    clousdServiceProvider: "",
    workloadName: ""
  };

  row = [];
  files: FileList = null;

  addTable(dynamic) {
    this.row.push(dynamic);
    this.dynamic = {
      clousdServiceProvider: "",
      workloadName: ""
    };

  }

  deleteRow(x) {
    this.row.splice(x, 1);
  }


  fileUpload(files) {
    let bytes;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    bytes = files[0].size;
    if (bytes === 0) {
      return '0 Byte';
    }
    console.log(files[0].type);
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const fileSize = bytes / Math.pow(1024, i);
    if (files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      if (sizes[i] === 'KB' && fileSize > 100) {
        this.toastr.error(files[0].name + ' is greater than 1 MB');
      } else {
        if (files && files[0]) {
          // const file = files[0];
          for (const file of files) {
            const reader = new FileReader();
            reader.onload = e => {
              let preview = reader.result;
              console.log("Reader result", reader.result);
            };
            reader.readAsDataURL(file);
          }
          this.files = files[0];
          // this.fileUploads(files[0]);
        }
      }
    } else {
      this.toastr.error('Only xlsx or xls files are allowed!');
    }



  }

  bulkuploadSave() {

    // if (this.clientForm.invalid) {
    //   this.toastr.error("Enter Valid Details")
    //   this.clientForm.markAllAsTouched();
    //   return;
    // }
    if (this.files === null) {
      this.toastr.error("Please Upload file")
      return;
    }

    var formData: any = new FormData();
    formData.append("file", this.files)
    formData.append("userName", this.clientForm.value.name)
    formData.append("email", this.clientForm.value.email)
    formData.append("phoneNumber", this.clientForm.value.number)
    formData.append("companyName", this.clientForm.value.companyName)
    formData.append("industry", this.clientForm.value.industry)
    formData.append("isByEmail", this.privacy.email)
    formData.append("isByPhone", this.privacy.phone)
    formData.append("isByPostmail", this.privacy.mail)
    formData.append("campaignId", this.campaignId)
    formData.append("cloudServices", this.row)
    this.ngxService.start();

    this.apiService.savebulkUpload(formData)
      .subscribe(res => {
        this.ngxService.stop();
        this.thankyou = true;
        this.progressBarValue = 0;
        this.temp = [];
        this.files = null;
        this.gotoTop();
        this.createForm();
        this.createForm2()
      }, err => {
        this.ngxService.stop();
        this.toastr.error(err)
      })
  }

  /**
* On save survey
*/
  submitted = false;
  save() {
    this.submitted = true;
    if (this.questionFormlist2.invalid) {
      this.questionFormlist2.markAllAsTouched();
      this.toastr.error("Please Answer all the Questions with Comments");
      return;
    }
    this.router.queryParams.subscribe(res => {
      this.campaignId = res.cId;
    })
    let responselist = [];
    Array.prototype.push.apply(this.questionFormlist1.value.question, this.questionFormlist2.value.question);
    this.questionFormlist1.value.question.map(el => {
      responselist.push({
        "questionId": el.questionID,
        "optionId": el.selected,
        "Description": el.description

      })
    })
    const survey = {
      "clientDetails": {
        "userName": this.clientForm.value.name,
        "email": this.clientForm.value.email,
        "phoneNumber": this.clientForm.value.number,
        "companyName": this.clientForm.value.companyName,
        "industry": this.clientForm.value.industry,
        "isByEmail": this.privacy.email,
        "isByPhone": this.privacy.phone,
        "isByPostmail": this.privacy.mail,
        "cloudServices": this.row
      },
      "applicationBrief": {
        "workloadName": this.applicationBriefForm.value.workloadName,
        "workloadBrief": this.applicationBriefForm.value.workloadBrief,
        "workloadEnvironment": this.applicationBriefForm.value.workloadEnvironment,
        "packageDetails": this.applicationBriefForm.value.packageDetails,
        "middlewareversion": this.applicationBriefForm.value.middlewareversion,
        "programminglanguage": this.applicationBriefForm.value.programminglanguage,
        "databaseRequirement": this.applicationBriefForm.value.databaseRequirement,
        "osVersions": this.applicationBriefForm.value.osVersions,
        "virtualizationDetail": this.applicationBriefForm.value.virtualizationDetail,
        "scalabilityRequirement": this.applicationBriefForm.value.scalabilityRequirement,
        "businessSLA": this.applicationBriefForm.value.businessSLA,
        "securityRequirement": this.applicationBriefForm.value.securityRequirement,
      },
      "disposition": {
        "cloudManagedApplicationService": "0",
        "sass": "0",
        "traditionalManagedServices": "0",
        "AlreadyinCloud": "0",
        "decommission": "0",
        "Modernize_to_cloud": "0",
        "ModernizeOnPrem": "0",
        "MigratetoIaaS": "0"
      },
      "questions": responselist,
      "campaignId": this.campaignId

    }
    this.ngxService.start();
    this.apiService.saveResponse(survey)
      .subscribe(res => {
        console.log(res);
        this.ngxService.stop();
        const dialogRef = this.dialog.open(SuccessComponent, {
          data: {
            message: "Thanks For your Feedback",
          },
          disableClose: true
        });
        dialogRef.afterClosed().subscribe((confirm) => {
          console.log("The dialog was closed", confirm);
          if (confirm) {
            this.submitted = false;
            this.progressBarValue = 0;
            this.temp = [];
            this.page = 3;
            this.gotoTop();
            this.createForm2();
          } else {
            this.thankyou = true;
            this.submitted = false;
            this.progressBarValue = 0;
            this.temp = [];
            this.gotoTop();
            this.createForm();
            this.createForm2()
          }
        });
      }, err => {
        this.ngxService.stop();
      })


  }








}
