import { AbstractControl, ValidationErrors } from '@angular/forms';
import { email } from './emaildomain'
export class EmailValidator {
    static cannotContainDomain(control: AbstractControl): ValidationErrors | null {
        var domain = email
        var idx1 = control.value.indexOf("@");
        if (idx1 > -1) {
            var splitStr = control.value.split("@");
            var sub = splitStr[1].split(".");
            var temp = domain.filter(s => {
                let a = s.split(".")
                console.log(a[0]);
                if (a[0].localeCompare(sub[0]) === 0)
                    return s
            });
            if (temp.length > 0) {
                return { cannotContainDomain: true }
            }
        }
        return null
    }
}