import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: "app-success",
  templateUrl: "./success.component.html",
  styleUrls: ["./success.component.scss"],
})
export class SuccessComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<SuccessComponent>) {}

  ngOnInit() {}

  onConfirmClick(value): void {
    this.dialogRef.close(value);
  }
}
