import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  constructor() {}
  user: any;
  /**
   * Returns the current user
   */
  public currentUser() {
    if (!this.user) {
      this.user = JSON.parse(sessionStorage.getItem("currentUser"));
    }
    return this.user;
  }
}
