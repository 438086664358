import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }
  user: any;


  // ROOT_URL = "http://localhost:8080";
  ROOT_URL = "https://ibmsurveyapi.demo.pacewisdom.in"

  public userdetails: any;

  login(data) {
    return this.http.post<any>(`${this.ROOT_URL}/users/authenticate`, data);
  }

  getToken() {
    return { headers: { Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")) } }
  }

  getAllRoles() {
    return this.http.get<any>(`${this.ROOT_URL}/role/getAllRoles`, this.getToken());
  }

  getAllUser() {
    return this.http.get<any>(`${this.ROOT_URL}/users/getAllUsers`, this.getToken());
  }

  createUser(data) {
    return this.http.post<any>(`${this.ROOT_URL}/users/register`, data, this.getToken());
  }

  getAllCampaign() {
    return this.http.get<any>(`${this.ROOT_URL}/campaign/getAllCampaigns`, this.getToken());
  }

  editUser(data, id) {
    return this.http.put<any>(`${this.ROOT_URL}/users/${id}/update`, data, this.getToken());
  }

  getroleUser(role) {
    const params = new HttpParams()
      .set('role', role)
    return this.http.get<any>(`${this.ROOT_URL}/users/getAllUsersByRole?` + params, this.getToken());
  }

  createCampaign(data) {
    return this.http.post<any>(`${this.ROOT_URL}/campaign/campaignCreate`, data, this.getToken());
  }

  editCampaign(data, id) {
    return this.http.put<any>(`${this.ROOT_URL}/campaign/campaignUpdate/${id}`, data, this.getToken());
  }

  getCampaignByuserId(id) {
    const params = new HttpParams()
      .set('id', id)
    return this.http.get<any>(`${this.ROOT_URL}/campaign/getCampaingByUserId?` + params, this.getToken());
  }


  deleteUser(id) {
    return this.http.delete<any>(`${this.ROOT_URL}/users/delete/${id}`, this.getToken());
  }

  deleteCampaign(id) {
    return this.http.delete<any>(`${this.ROOT_URL}/campaign/delete/${id}`, this.getToken());
  }

  getsurveyResponse() {
    return this.http.get<any>(`${this.ROOT_URL}/survey/getAllClients`, this.getToken());

  }

  getsurveyResponseById(id) {
    return this.http.get<any>(`${this.ROOT_URL}/survey/getSurveyResponsesByUserId/${id}`, this.getToken());

  }

  saveResponse(data) {
    return this.http.post<any>(`${this.ROOT_URL}/survey/saveSurvey`, data);
  }

  getAllQuestions() {
    return this.http.get<any>(`${this.ROOT_URL}/questions/getAllQuestionsAndOptions`);
  }

  UpdatePassword(data, id) {
    return this.http.put<any>(`${this.ROOT_URL}/users/${id}/updatepassword`, data, this.getToken());
  }

  getdetailView(id, campaignId) {
    const params = new HttpParams()
      .set('campaignId', campaignId)
    return this.http.get<any>(`${this.ROOT_URL}/survey/getAllSurveyByClient/${id}?` + params, this.getToken());

  }

  getReportdetailByID(id) {
    return this.http.get<any>(`${this.ROOT_URL}/reports/getReportData/${id}`, this.getToken());

  }

  getOverallreport(id, campaignId) {
    // const params = new HttpParams()
    //   .set('campaignId', campaignId)
    return this.http.get<any>(`${this.ROOT_URL}/reports/getoverAllReportDataByClientId/${id}?campaignId=${campaignId}`, this.getToken());

  }

  updateReport(id, data) {
    return this.http.put<any>(`${this.ROOT_URL}/survey/updateSurvey/${id}`, data, this.getToken());
  }

  updateReportDetails(id, data) {
    return this.http.put<any>(`${this.ROOT_URL}/reports/reportUpdate/${id}`, data, this.getToken());
  }



  savePreviewReport(data) {
    return this.http.post<any>(`${this.ROOT_URL}/reports/savePreviewText`, data, this.getToken());
  }

  updatePreviewReport(id, data) {
    return this.http.put<any>(`${this.ROOT_URL}/reports/updatePreviewText/${id}`, data, this.getToken());
  }

  getPreviewReport(cid, campId) {
    return this.http.get<any>(`${this.ROOT_URL}/reports/getPreviewText/${cid}?cid=${campId}`, this.getToken());
  }


  getReportvalue(id, cid) {
    return this.http.get<any>(`${this.ROOT_URL}/reports/getReportValues/${id}?clientId=${cid}`, this.getToken());

  }

  getcampaignsurvey() {
    return this.http.get<any>(`${this.ROOT_URL}/survey/getCampaingsForSurveyResponses`, this.getToken());

  }
  getsurveyByCampaignId(id) {
    return this.http.get<any>(`${this.ROOT_URL}/survey/getSurvey/${id}?`, this.getToken());

  }

  uploadLogo(id, data) {
    const formData = new FormData()
    formData.append('file', data)
    return this.http.post<any>(`${this.ROOT_URL}/reports/logoUpload/${id}`, formData, this.getToken());
  }

  generatePDF(data) {
    return this.http.post<any>(`${this.ROOT_URL}/reports/generatePdfFile`, data, this.getToken());

  }

  downloadReports(id, cid) {
    return this.http.get<any>(`${this.ROOT_URL}/reports/getReport/${id}?cid=${cid}`, this.getToken());

  }

  getLogoforClient(id) {
    return this.http.get<any>(`${this.ROOT_URL}/reports/logoForClients/${id}`, this.getToken());
  }


  savebulkUpload(data) {
    return this.http.post<any>(`${this.ROOT_URL}/survey/csvFileUpload`, data);
  }


}
