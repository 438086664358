import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-updatepassword',
  templateUrl: './updatepassword.component.html',
  styleUrls: ['./updatepassword.component.scss']
})
export class UpdatepasswordComponent implements OnInit {
  formGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<UpdatepasswordComponent>,
    private formBuilder: FormBuilder,
    private apiservice: ApiService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.createForm()
  }

  /**
  * Create form
  */
  createForm() {
    this.formGroup = this.formBuilder.group({
      password: ["", [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{5,15}$/)
      ]],
      newPassword: ["", [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{5,15}$/)
      ]],
    });
  }


  getError(el) {
    switch (el) {
      case "password":
        if (this.formGroup.get("password").hasError("required")) {
          return "Password required";
        } else if (this.formGroup.get("password").hasError("minlength")) {
          return "Minimum 5 characters required"
        } else if (this.formGroup.get("password").hasError("maxlength")) {
          return "Maximum of 15 characters are allowed"
        } else if (this.formGroup.get("password").hasError("pattern")) {
          return "There must be 1 digit \n, 1 lowercase letter \n,1 uppercase letter"
        }
        break;
      case "newPassword":
        if (this.formGroup.get("newPassword").hasError("required")) {
          return "Password required";
        } else if (this.formGroup.get("newPassword").hasError("minlength")) {
          return "Minimum 5 characters required"
        } else if (this.formGroup.get("newPassword").hasError("maxlength")) {
          return "Maximum of 15 characters are allowed"
        } else if (this.formGroup.get("newPassword").hasError("pattern")) {
          return "There must be 1 digit \n, 1 lowercase letter \n,1 uppercase letter"
        }
        break;
      default:
        return "";
    }
  }

  /**
 * On submit
 */
  onSubmit() {
    console.log(this.formGroup.value);

    // stop here if form is invalid
    if (this.formGroup.invalid) {
      return;
    }
    this.ngxService.startLoader('loader-01'); 
    this.ngxService.start()

    const details = {
      "password": this.formGroup.value.password,
      "newpassword": this.formGroup.value.newPassword
    }

    const id = JSON.parse(sessionStorage.getItem("id"));
    this.apiservice.UpdatePassword(details, id)
      .subscribe(res => {
        console.log(res);
        this.ngxService.stopLoader('loader-01'); 
        this.ngxService.stop();
        this.dialogRef.close(true);
      }, err => {
        // this.ngxService.stop();
        this.toastr.error(err)
      })


  }

}
