const UsersData = [
  {

    role: "Admin",
    sidenav: [
      {
        path: "/dashboard/survey-dashboard",
        name: "Dashboard",
      },
      {
        path: "/users/list",
        name: "User",
      },
      {
        path: "/campaign/list",
        name: "Campaign",
      },

      {
        path: "/survey-response/list",
        name: "Survey Responses",
      }

    ],
  },
  {

    role: "Marketing User",
    sidenav: [
      {
        path: "/dashboard/survey-dashboard",
        name: "Dashboard",
      },
      {
        path: "/campaign/list",
        name: "Campaign",
      },
      {
        path: "/survey-response/list",
        name: "Survey Responses",
      },
    ],
  },
  {
    role: "Reporter User",
    sidenav: [
      {
        path: "/dashboard/survey-dashboard",
        name: "Dashboard",
      },
      {
        path: "/survey-response/CampaignResponselist",
        name: "Survey Responses",
      },
    ]
  }
];
export { UsersData };
